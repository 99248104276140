
import bossApi from '../utils/bossApi'
// import bossXypq from '../utils/bossXypq'

// 包裹校验
export function checkOrderExpress(data) {
  return bossApi({ url: 'front/storeOrderExpress/checkOrderExpress', method: 'POST', data })
}
// 包裹信息
export function getOrderExpress(data) {
  return bossApi({ url: 'front/storeOrderExpress/getOrderExpress', method: 'POST', data })
}
// 包包裹商品信息
export function getStoreOrderExpressProduct(data) {
  return bossApi({ url: 'front/storeOrderExpress/getStoreOrderExpressProduct', method: 'POST', data })
}
// 包包裹商品信息确认
export function customerExpressConfirm(data) {
  return bossApi({ url: 'front/storeOrderExpress/customerExpressConfirm', method: 'POST', data })
}

/**
 * 盘存信息·1
 * @kadiam 2022-10-27
*/
// 盘存订单验证(前店)
export function frontendTaking_checkTaking(data) {
  return bossApi({ url: 'front/frontendTaking/checkTaking', method: 'POST', data })
}
// 盘存订单验证(后仓)
export function backendTaking_checkTaking(data) {
  return bossApi({ url: 'front/backendTaking/checkTaking', method: 'POST', data })
}
// 后仓
// 公司盘存信息
export function backendTakingInfo(data) {
  return bossApi({ url: 'front/backendTaking/backendTakingInfo', method: 'POST', data })
}
// 盘存公司信息
export function selectTakingOrder(data) {
  return bossApi({ url: 'front/backendTaking/selectTakingOrder', method: 'POST', data })
}
// 盘存列表
export function backendTakingProductList(data) {
  return bossApi({ url: 'front/backendTaking/backendTakingProductList', method: 'POST', data })
}
// 前店
// 公司盘存信息
export function takingSurvey(data) {
  return bossApi({ url: 'front/frontendTaking/takingSurvey', method: 'POST', data })
}
// 盘存列表
export function frontendTaking_takingProductList(data) {
  return bossApi({ url: 'front/frontendTaking/takingProductList', method: 'POST', data })
}
